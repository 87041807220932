import React from 'react'
import './casinosList.css'

const CasinosList = ({ title, casinosList }) => {


    return (
        <div>
            {title && <h3 className="list-title">&#128270; {title}</h3>}
            <div className="casinos-list">
                {casinosList?.length && casinosList.map((casinoItem) => {
                    return (
                        <div className="item-wrapper" key={casinoItem?.casinoName}>
                            <div class="casino-top">
                                <div className="logo-wrapper">
                                    <a href={casinoItem?.affiliateLinkki} target="_blank" rel="noopener noreferrer">
                                    <img src={`/images/listalogot/${casinoItem?.kuvalinkki}`} alt={casinoItem?.casinoName} width="180px" height="100px"/></a>
                                        <a href={casinoItem?.affiliateLinkki} target="_blank" rel="noopener noreferrer">{casinoItem?.casinoName}</a>
                                    <div className="casino-rate">
                                    {Array(casinoItem?.arvostelu).fill(<span>&#9733;</span>)}
                                </div>
                                </div>
                                <div className="context-box content-info">
                                    <div className="content-button">
                                        <a href={casinoItem?.affiliateLinkki} className="btn" target="_blank" rel="noopener noreferrer">Pelaa &#10154;</a>
                                        {casinoItem?.bonukset && (  
                                            <div className="offer-item">
                                                <i>{casinoItem?.bonukset}</i>
                                            </div>
                                    )}
                                    </div>
                                </div>
                                <div className="context-box context-second">
                                    <div className="content-text">
                                        {casinoItem?.teksti}
                                        {casinoItem?.termsLink && <a href={casinoItem?.affiliateLinkki} className="terms-link" target="_blank" rel="noopener noreferrer">Ehdot ja säännöt...</a>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CasinosList
