import React from 'react'
import './bodyContent.css'
import { img } from "gatsby-plugin-image"

const BodyContent = ({ data }) => {

    return (
        <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: data }}
        />
    )
}

export default BodyContent
